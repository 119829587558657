import React from "react"
import { useTranslation } from "react-i18next"
import { observer } from "mobx-react"
import { IconButton } from "@material-ui/core"
import { color } from "../../theme/color"
import { useStores } from "../../models/root-store"
import { makeStyles } from "@material-ui/styles"
import LogoutIcon from "../../static/Logout.svg"
import { LanguageSelector } from "./LanguageSelector"
import { languageOptions } from "../../models/article-store"

const useStyles = makeStyles({
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    background: color.white,
    borderBottom: `0.5px solid ${color.dimmer}`,
  },
  headerText: {
    fontSize: 14,
    fontWeight: "bold",
    textTransform: "uppercase",
    display: "flex",
    flex: "1 0 auto",
  },
  left: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  right: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  navItem: {
    minWidth: 66,
    height: 60,
    padding: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderLeft: `0.5px solid ${color.dimmer}`,
  },
  userPicture: {
    height: 35,
    width: 35,
    borderRadius: "50%",
    backgroundColor: color.text,
  },
  user: {
    margin: 0,
    marginLeft: 10,
    marginRight: 20,
    marginBottom: 2,
    color: color.text,
    fontSize: 16,
    fontWeight: 600,
  },
  logoutButton: {
    borderRadius: 0,
    height: 60,
    width: 66,
  },
  icon: {
    color: color.text,
  },
  currentUser: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
})

const Header: React.FC = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { userStore, articleStore } = useStores()
  const { currentLanguage, setCurrentLanguage } = articleStore

  const toUserScreen = () => {
    throw new Error("todo")
  }

  const logout = () => {
    userStore.logout()
  }

  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <LanguageSelector
          options={languageOptions}
          value={currentLanguage}
          onLangChange={setCurrentLanguage}
        />
      </div>
      <div className={classes.right}>
        <div className={classes.navItem}>
          <IconButton className={classes.logoutButton} onClick={logout}>
            <img src={LogoutIcon} className={classes.icon} alt={t("header.logout")} />
          </IconButton>
        </div>
      </div>
    </div>
  )
})

export default Header
