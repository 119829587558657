import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { observer } from "mobx-react"
import { makeStyles } from "@material-ui/core"
import moment from "moment"
import { Screen, Table } from "../components"
import { useStores } from "../models/root-store"
import { createStoryPath, StoryPaths } from "../routers/Paths"
import { color } from "../theme"

const useStyles = makeStyles({
  root: {
    backgroundColor: color.transparent,
  },
})

export const StoriesScreen: React.FC = observer(() => {
  const { articleStore } = useStores()
  const history = useHistory()
  const classes = useStyles()
  const { t } = useTranslation()

  const formattedStories = () =>
    articleStore.stories.map(story => ({
      ...story,
      created: `${moment(story.createdAt).format("DD.MM.YYYY")}${
        story.createdBy?.name ? `, ${story.createdBy.name.split(" ")[0]}` : ""
      }`,
      updated: `${moment(story.updatedAt).format("DD.MM.YYYY")}, ${
        story.updatedBy?.name ? `, ${story.updatedBy.name.split(" ")[0]}` : ""
      }`,
    }))

  const editStory = (articleId: number) => {
    history.push({
      pathname: createStoryPath(StoryPaths.update, articleId),
    })
  }

  return (
    <Screen header="screens.stories">
      <div className={classes.root}>
        <Table
          total={articleStore.stories.length}
          items={formattedStories()}
          headerTitles={[t("title"), t("created"), t("updated")]}
          toSingle={editStory}
        />
      </div>
    </Screen>
  )
})

export default StoriesScreen
