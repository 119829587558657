import React, { FC } from "react"
import { makeStyles } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { Button } from "."

export interface SubmitButtonProps {
  loading: boolean
  disabled: boolean
  onClick: () => void
}

const useStyles = makeStyles({
  buttonContent: {
    fontSize: 18,
    textTransform: "capitalize",
    padding: "0px 30px",
    margin: 0,
  },
})

export const SubmitButton: FC<SubmitButtonProps> = props => {
  const { loading, disabled, onClick } = props
  const { buttonContent } = useStyles()
  const { t } = useTranslation()
  return (
    <Button loading={loading} disabled={disabled} onClick={onClick}>
      <p className={buttonContent}>{t("common.save")}</p>
    </Button>
  )
}
