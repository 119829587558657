import React from "react"
import { observer } from "mobx-react"
import { Theme } from "@material-ui/core/styles"
import { AppBar, Toolbar } from "@material-ui/core"
import { color } from "../../theme/color"
import Snackbar from "./Snackbar"
import Header from "./Header"
import { Sidebar } from "./Sidebar"
import { SidebarItems } from "./nav"
import { load, save, LAYOUT_SIDEBAR_OPEN } from "../../utils/storage"
import classNames from "classnames"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    backgroundColor: color.background,
    flexGrow: 1,
    zIndex: 9,
    overflow: "hidden",
    position: "relative",
    display: "flex",
  },
  appBar: {
    height: 60,
    zIndex: theme.zIndex.drawer + 1,
    background: color.white,
    boxShadow: "none",
    marginLeft: 60,
    width: `calc(100% - 60px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    height: 60,
    zIndex: theme.zIndex.drawer + 1,
    background: color.white,
    boxShadow: "none",
    marginLeft: 364,
    width: `calc(100% - 364px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  content: {
    flex: 1,
    marginTop: "60px",
  },
  toolbarRoot: {
    minHeight: 60,
  },
  toolbar: theme.mixins.toolbar,
  loading: {
    color: color.primary,
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  mainArea: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignContent: "stretch",
    minHeight: "100vh",
  },
}))

interface LayoutProps {}

const Layout: React.FC<LayoutProps> = observer(props => {
  const classes = useStyles()
  const { children } = props
  const [open, setOpen] = React.useState(load<boolean>(LAYOUT_SIDEBAR_OPEN) !== false)

  const handleDrawerOpen = () => {
    setOpen(true)
    save(LAYOUT_SIDEBAR_OPEN, true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
    save(LAYOUT_SIDEBAR_OPEN, false)
  }

  return (
    <div className={classes.root}>
      <AppBar className={classNames(classes.appBar, { [classes.appBarShift]: open })}>
        <Toolbar classes={{ root: classes.toolbarRoot }} disableGutters>
          <Header />
        </Toolbar>
      </AppBar>
      <div className={classes.mainArea}>
        <Sidebar
          open={open}
          handleOpen={handleDrawerOpen}
          handleClose={handleDrawerClose}
          location={window.location}
          menuItems={SidebarItems}
        />
        <main className={classes.content}>{children}</main>
      </div>
      <Snackbar />
    </div>
  )
})

export default Layout
