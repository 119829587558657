import { ApisauceInstance, create, ApiResponse, ApiErrorResponse } from "apisauce"
import { ApiConfig, DEFAULT_API_CONFIG } from "./api.config"
import * as Types from "./api.types"
import { save, load } from "../utils/storage"
import { Article, NewArticle, NewMessage } from "../utils/interfaces"

export type Response<T> = ApiResponse<T> | ApiErrorResponse<T>

/**
 * Manages all requests to the API.
 */
export class Api {
  api!: ApisauceInstance

  config: ApiConfig

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   */
  async setup() {
    const token = load("token")
    // construct the apisauce instance
    this.api = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: "application/json",
        // eslint-disable-next-line @typescript-eslint/camelcase
        access_token: token,
      },
    })
    return Boolean(token)
  }

  /**
   * Login call to the API.
   * @param email Email
   * @param password Password
   * @returns Boolean indicating whether the login attempt was successful or not
   */
  async login(email: string, password: string): Promise<boolean> {
    const response: Response<Types.LoginResponse> = await this.api.post(`auth/admin-login`, {
      email,
      password,
    })
    if (!response.ok || !response.data || !response.data.token) return false
    save("token", response.data.token)
    await this.setup()
    return true
  }

  /**
   * Fetches all articles (generic and story)
   */
  async getArticles(language?: string): Promise<Article[] | undefined> {
    const response: Response<Article[]> = await this.api.get("article", { language })
    if (!response.ok) return undefined
    return response.data
  }

  /**
   * Fetches a single article by ID
   * @param id Article id
   */
  async getArticle(id: number): Promise<Article | undefined> {
    const response: Response<Article> = await this.api.get(`article/${id}`)
    if (!response.ok) return undefined
    return response.data
  }

  /**
   * Creates a new article
   * @param article New article
   * @returns Whether the article was created successfully or not
   */
  async postArticle(article: Partial<NewArticle>): Promise<Article | undefined> {
    const response: Response<Article> = await this.api.post("article", article)
    if (response.ok) return response.data
    return undefined
  }

  /**
   * Updates an article
   * @param article Updatead article with ID
   * @returns Whether the article was updated successfully or not
   */
  async updateArticle(article: Partial<Article>): Promise<Article | undefined> {
    const response: Response<Article> = await this.api.put("article", article)
    if (response.ok) return response.data
    return undefined
  }

  /**
   * Deletes an article
   * @param article Deleted article with ID
   * @returns Whether the article was deleted successfully or not
   */
  async deleteArticle(article: Partial<Article>): Promise<boolean> {
    const response: Response<boolean> = await this.api.delete("article/" + article.id)
    if (response.ok) return true
    return false
  }

  /**
   * Sets a new password
   * @param code Password reset code
   * @param email User email
   * @param password New password
   * @returns Whether password was changed successfully or not
   */
  async setPassword(code: string, email: string, password: string): Promise<boolean> {
    const response: Response<boolean> = await this.api.post("auth/set-password", {
      code,
      email,
      password,
    })
    return response.ok
  }

  /**
   * Sends group message
   * @param message New message
   * @returns Whether the message was send successfully or not
   */
  async sendGroupMessage(message: Partial<NewMessage>): Promise<boolean> {
    const response: Response<NewMessage> = await this.api.post("group-message/", message) //Tää pitää vielä muuttaa
    return response.ok
  }
}
