import React, { FC, Fragment, ChangeEvent } from "react"
import { useTranslation } from "react-i18next"
import { observer } from "mobx-react"
import { EditorState } from "react-draft-wysiwyg"
import { makeStyles } from "@material-ui/core"
import { Input, CustomEditor } from "../"
import { color } from "../../theme"

export const useStyles = makeStyles({
  formContainer: {
    marginBottom: 30,
    display: "flex",
    flexFlow: "column nowrap",
  },
  header: {
    fontSize: 24,
    fontWeight: "bold",
    marginTop: 0,
  },
  inputContainer: {
    marginBottom: 30,
  },
  inputHeader: {
    fontSize: 18,
    color: color.dim,
    textIndent: 15,
    margin: "8px 0",
  },
  separator: {
    height: 1,
    width: "100%",
    margin: "30px 0",
    backgroundColor: color.line,
  },
  input: {
    fontSize: 30,
    fontWeight: 600,
    fontFamily: "Montserrat",
    minHeight: 60,
    marginBottom: 26,
  },
})

interface ArticleFormProps {
  title?: string
  setTitle: (val: string) => void
  editorState: EditorState
  setEditorState: (val: EditorState) => void
  className?: string
}

export const ArticleForm: FC<ArticleFormProps> = observer(props => {
  const { title, setTitle, editorState, setEditorState, className } = props
  const { t } = useTranslation()
  const classes = useStyles()

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)

  const renderForm = () => {
    return (
      <div className={`${classes.formContainer} ${className}`}>
        <h3 className={classes.header}>{t("form.content")}</h3>
        <div className={classes.inputContainer}>
          <p className={classes.inputHeader}>{t("form.title")}</p>
          <Input
            className={classes.input}
            value={title}
            onChange={handleTitleChange}
            placeholder="placeholder.header"
          />
          <p className={classes.inputHeader}>{t("form.text")}</p>
          <CustomEditor state={editorState} onChange={setEditorState} />
        </div>
      </div>
    )
  }

  return <Fragment>{renderForm()}</Fragment>
})
