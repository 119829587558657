import { makeStyles } from "@material-ui/styles"
import { color } from "../../theme"

const WRAPPER_MARGIN = "1em"

export const useEditorStyles = makeStyles({
  toolbar: {
    border: "none",
    backgroundColor: color.palette.offWhite,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
  },
  wrapper: {
    marginTop: WRAPPER_MARGIN,
    marginBottom: WRAPPER_MARGIN,
    border: `1px solid ${color.lighterLine}`,
    borderRadius: 3,
  },
  editor: {
    minHeight: "40vh",
    maxHeight: "40vh",
    padding: "0 20px",
    backgroundColor: color.white,
  },
  button: {
    backgroundColor: color.transparent,
    border: "none",
    borderRadius: 3,
    transition: "all 250ms",
    "&:hover": {
      backgroundColor: color.line,
    },
  },
})
