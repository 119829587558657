export enum ApplicationEnvironment {
  STAGE = "https://moona-api.stage.geniem.io",
  LOCAL = "http://localhost:1337",
  PRODUCTION = "https://moona-api.production.geniem.io",
}

// Select API URL to use
let API_URL = ApplicationEnvironment.PRODUCTION
if (process.env.NODE_ENV === "development") API_URL = ApplicationEnvironment.LOCAL
if (window.location.hostname.includes(".stage.geniem.io")) API_URL = ApplicationEnvironment.STAGE

/**
 * The options used to configure the API.
 */
export interface ApiConfig {
  /**
   * The URL of the api.
   */
  url: string

  /**
   * Milliseconds before we timeout the request.
   */
  timeout: number
}

/**
 * The default configuration for the app.
 */
export const DEFAULT_API_CONFIG: ApiConfig = {
  url: API_URL,
  timeout: 10000,
}
