import { types, flow, getParent, Instance, SnapshotOut, destroy } from "mobx-state-tree"
import moment from "moment"
import { omit } from "ramda"
import { RootStore } from "../root-store"
import { Article, SubmitArticle } from "../../utils/interfaces"
import { withEnvironment } from "../extensions"

const languages = ["eng", "fin", "swe"]

export const LanguageOption = types.model("LanguageOption", {
  name: types.enumeration(languages),
  value: types.enumeration(languages),
})
export interface LanguageOption extends Instance<typeof LanguageOption> {}

export const languageOptions: LanguageOption[] = languages.map(l => ({ name: l, value: l }))

const ArticleModel = types.model("ArticleModel", {
  id: types.identifierNumber,
  title: types.string,
  text: types.maybe(types.string),
  category: types.string,
  createdAt: types.Date,
  updatedAt: types.Date,
  createdBy: types.maybe(types.model({ name: types.maybeNull(types.string) })),
  updatedBy: types.maybe(types.model({ name: types.maybeNull(types.string) })),
  language: types.string,
})

type ArticleModel = Instance<typeof ArticleModel>

export const ArticleStore = types
  .model("ArticleStore", {
    articles: types.optional(types.array(ArticleModel), []),
    stories: types.optional(types.array(ArticleModel), []),
    selectedArticle: types.maybe(ArticleModel),
    currentLanguage: LanguageOption,
    fetching: false,
  })
  .extend(withEnvironment)
  .actions(self => ({
    addArticle: (article: ArticleModel) => self.articles.push(article),
    addStory: (story: ArticleModel) => self.stories.push(story),
    clear: () => {
      self.articles.clear()
      self.stories.clear()
      self.selectedArticle && destroy(self.selectedArticle)
    },
  }))
  .actions(self => ({
    getArticles: flow(function*() {
      const { api } = self.environment
      self.fetching = true
      const articles: Article[] | null = yield api.getArticles(self.currentLanguage.value)
      if (articles) {
        self.articles.clear()
        self.stories.clear()
        articles.forEach((a: Article) => {
          a.createdAt = moment(a.createdAt).toDate()
          a.updatedAt = moment(a.updatedAt).toDate()
          if (a.category === "story") self.addStory(a)
          if (a.category === "generic") self.addArticle(a)
        })
      } else {
        self.articles.clear()
        self.stories.clear()
      }
      return (self.fetching = false)
    }),
    getArticleById: flow(function*(id: number) {
      const { api } = self.environment
      const { notificationStore } = getParent(self) as RootStore
      self.fetching = true
      const article: Article = yield api.getArticle(id)
      self.fetching = false
      if (!article) notificationStore.setNotification("articles.error", "error")
      else {
        article.createdAt = moment(article.createdAt).toDate()
        article.updatedAt = moment(article.updatedAt).toDate()
        return (self.selectedArticle = article)
      }
    }),
    clearSelected: () => {
      return self.selectedArticle && destroy(self.selectedArticle)
    },
  }))
  .actions(self => ({
    submit: flow(function*(article: SubmitArticle, id?: number) {
      const { notificationStore } = getParent(self) as RootStore
      const { api } = self.environment

      self.fetching = true
      let res
      if (id) res = yield api.updateArticle({ ...article, id })
      else res = yield api.postArticle(article)

      self.fetching = false
      if (!res) {
        notificationStore.setNotification("articles.error", "error")
        return false
      }

      yield self.getArticleById(id || res.id)
      yield self.getArticles()
      return true
    }),
    setCurrentLanguage: (lang: LanguageOption) => {
      self.currentLanguage = lang
      self.getArticles()
    },
    delete: flow(function*(article: Article) {
      const { notificationStore } = getParent(self) as RootStore
      const { api } = self.environment

      self.fetching = true
      let res
      if (article.id) {
        res = yield api.deleteArticle(article)
      }

      self.fetching = false
      if (!res) {
        notificationStore.setNotification("articles.error", "error")
        return false
      }

      yield self.getArticles()
      return true
    }),
  }))

  .postProcessSnapshot(omit(["selectedArticle"]))

type ArticleStoreType = Instance<typeof ArticleStore>
export interface ArticleStoreInterface extends ArticleStoreType {}
type ArticleStoreSnapshotType = SnapshotOut<typeof ArticleStore>
export interface ArticleStoreSnapshot extends ArticleStoreSnapshotType {}
