import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { observer } from "mobx-react"
import { makeStyles } from "@material-ui/core"
import moment from "moment"
import { Screen, Table } from "../components"
import { useStores } from "../models/root-store"
import { createContentPath, ContentPaths } from "../routers/Paths"
import { color } from "../theme"

const useStyles = makeStyles({
  root: {
    backgroundColor: color.transparent,
  },
})

export const ContentViewsScreen: React.FC = observer(() => {
  const { articleStore } = useStores()
  const history = useHistory()
  const classes = useStyles()
  const { t } = useTranslation()

  const formattedArticles = () =>
    articleStore.articles.map(article => ({
      ...article,
      viewTitle: article.title,
      created: `${moment(article.createdAt).format("DD.MM.YYYY")}${
        article.createdBy?.name ? `, ${article.createdBy.name.split(" ")[0]}` : ""
      }`,
      updated: `${moment(article.updatedAt).format("DD.MM.YYYY")}${
        article.updatedBy?.name ? `, ${article.updatedBy.name.split(" ")[0]}` : ""
      }`,
    }))

  const editArticle = (articleId: number) => {
    history.push({
      pathname: createContentPath(ContentPaths.update, articleId),
    })
  }

  return (
    <Screen header="screens.contentViews">
      <div className={classes.root}>
        <Table
          total={articleStore.articles.length}
          items={formattedArticles()}
          headerTitles={[t("viewTitle"), t("created"), t("updated")]}
          toSingle={editArticle}
        />
      </div>
    </Screen>
  )
})

export default ContentViewsScreen
