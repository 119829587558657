import { types, flow, getParent, Instance, SnapshotOut } from "mobx-state-tree"
import { RootStore } from "../root-store"
import { SendMessage } from "../../utils/interfaces"
import { withEnvironment } from "../extensions"

const MessageModel = types.model("MessageModel", {
  id: types.identifierNumber,
  title: types.string,
  text: types.maybe(types.string),
  language: types.string,
})

type MessageModel = Instance<typeof MessageModel>

export const GroupMessageStore = types
  .model("MessageModel", {
    messages: types.optional(types.array(MessageModel), []),
    fetching: false,
  })
  .extend(withEnvironment)
  .actions(self => ({
    addMessage: (message: MessageModel) => self.messages.push(message),
    clear: () => {
      self.messages.clear()
    },
  }))
  .actions(self => ({
    sendGroupMessage: flow(function*(message: SendMessage) {
      const { notificationStore } = getParent(self) as RootStore
      const { api } = self.environment

      self.fetching = true
      const res = yield api.sendGroupMessage(message)

      self.fetching = false
      if (!res) {
        notificationStore.setNotification("sendGroupMessage.error", "error")
        return false
      }

      return true
    }),
  }))

type GroupMessageStoreType = Instance<typeof GroupMessageStore>
export interface AGroupMessageStoreInterface extends GroupMessageStoreType {}
type GroupMessageStoreSnapshotType = SnapshotOut<typeof GroupMessageStore>
export interface GroupMessageStoreSnapshot extends GroupMessageStoreSnapshotType {}
