import React, { ChangeEvent } from "react"
import { ListSubheader, Select, MenuItem } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { useTranslation } from "react-i18next"
import { color } from "../../theme/color"
import { LanguageOption } from "../../models/article-store/article-store"
import { SelectProps } from "@material-ui/core/Select"

const useStyles = makeStyles({
  container: {
    width: 300,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    position: "relative",
    color: color.text,
    fontFamily: "Montserrat",
  },
  input: {
    height: 50,
    fontSize: 16,
    color: color.text,
    backgroundColor: color.white,
    justifyContent: "center",
    border: "none",
    padding: "0px 15px",
    "&::placeholder": {
      color: color.dim,
    },
  },
  menuItem: {
    fontSize: 18,
    backgroundColor: color.white,
    textTransform: "capitalize",
  },
  selectRoot: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    height: 40,
    backgroundColor: color.white,
    textTransform: "capitalize",
    color: color.white,
    "&:focus": {
      backgroundColor: color.white,
    },
  },
  selectDisabled: {
    color: color.dim,
    cursor: "not-allowed",
    fontSize: 16,
  },
  menuRoot: {
    maxHeight: "50%",
  },
  placeholder: {
    opacity: 0.5,
    color: color.dim,
  },
  languageValue: {
    display: "flex",
    flex: 1,
    alignItems: "center",
  },
  language: {
    fontSize: 18,
    fontWeight: "bold",
    textTransform: "uppercase",
    marginRight: 16,
    marginLeft: 32,
    color: color.text,
    fontFamily: "Montserrat",
  },
  selected: {
    color: color.text,
    fontFamily: "Montserrat",
  },
})

interface Option {
  name: string
  value: string
}

interface SelectInputProps {
  width?: string
  maxLength?: number
  max?: number
  min?: number
  options: Option[]
  onLangChange: (language: LanguageOption) => void
}

export const LanguageSelector: React.FC<SelectInputProps & SelectProps> = props => {
  const { id, width, options, value, onLangChange, disabled } = props
  const { t } = useTranslation()
  const classes = useStyles()

  const handleChange = (
    e: ChangeEvent<{ name?: string | undefined; value: unknown; id?: any }>
  ) => {
    if (!e.target.value) return
    onLangChange({
      name: e.target.value as string,
      value: e.target.value as string,
    })
  }

  const renderOptions = () =>
    options.map(option => (
      <MenuItem key={option.value} value={option.value} classes={{ root: classes.menuItem }}>
        {t(`languages.${option.name}`)}
      </MenuItem>
    ))

  const renderValue = (val: unknown) => (
    <div className={classes.languageValue}>
      <p className={classes.language}>{t("header.language")}</p>
      <span className={classes.selected}>{t(`languages.${(val as Option).name}`)}</span>
    </div>
  )

  const styles = width ? { width } : {}
  return (
    <div className={classes.container} style={styles}>
      <Select
        id={id}
        value={value}
        onChange={handleChange}
        className={classes.input}
        classes={{
          select: `${classes.selectRoot} ${value === "" && classes.placeholder}`,
          disabled: classes.selectDisabled,
        }}
        IconComponent={undefined}
        renderValue={renderValue}
        disabled={disabled}
        displayEmpty
        disableUnderline
        MenuProps={{ MenuListProps: { classes: { root: classes.menuRoot } } }}
      >
        <ListSubheader>{t("languages.selectLanguage")}</ListSubheader>
        {renderOptions()}
      </Select>
    </div>
  )
}
