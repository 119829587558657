import React, { FC } from "react"
import { Editor } from "react-draft-wysiwyg"
import { EditorState } from "draft-js"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { useEditorStyles } from "./styles"
import { toolbarSettings } from "../../utils/editor"

interface EditorProps {
  state: EditorState
  onChange: (arg0: EditorState) => void
}

export const CustomEditor: FC<EditorProps> = ({ state, onChange }) => {
  const { toolbar, wrapper, editor, button } = useEditorStyles()
  const TOOLBAR = toolbarSettings(button)

  return (
    <Editor
      toolbar={TOOLBAR}
      editorState={state}
      onEditorStateChange={onChange}
      toolbarClassName={toolbar}
      wrapperClassName={wrapper}
      editorClassName={editor}
      stripPastedStyles
    />
  )
}

export default CustomEditor
