const OPTIONS = ["inline", "blockType", "fontSize", "list", "link", "emoji", "history"]

export const toolbarSettings = (buttonClassName: string) => ({
  options: OPTIONS,
  inline: {
    options: ["bold", "italic", "underline"],
    bold: {
      className: buttonClassName,
    },
    italic: {
      className: buttonClassName,
    },
    underline: {
      className: buttonClassName,
    },
  },
  list: {
    options: ["ordered", "unordered"],
    ordered: {
      className: buttonClassName,
    },
    unordered: {
      className: buttonClassName,
    },
  },
  blockType: {
    className: buttonClassName,
  },
  fontSize: {
    className: buttonClassName,
  },
  link: {
    className: buttonClassName,
  },
  emoji: {
    className: buttonClassName,
  },
  remove: {
    className: buttonClassName,
  },
  history: {
    undo: {
      className: buttonClassName,
    },
    redo: {
      className: buttonClassName,
    },
  },
})
