import { RootStore } from "../root-store/root-store"
import { Instance, SnapshotOut, types, flow, getParent } from "mobx-state-tree"
import { withEnvironment } from "../extensions"
import { clear } from "../../utils/storage"

const LoginStates = ["LOGGED_IN" as const, "LOGGED_OUT" as const, "IN_PROGRESS" as const]

/**
 *  A model for the status of the current user
 */
export const UserStoreModel = types
  .model("UserStore")
  .props({
    state: types.enumeration("State", LoginStates),
    user: types.maybe(types.model("User")),
    token: types.maybe(types.string),
    loginError: types.maybe(types.string),
  })
  .views(self => ({
    get isLoggedIn() {
      //return true
      console.log(self.state)
      return self.state === "LOGGED_IN"
    },
    get fetching() {
      return self.state === "IN_PROGRESS"
    },
  }))
  .extend(withEnvironment)
  .actions(self => ({
    login: flow(function*(username: string, password: string) {
      const { notificationStore } = getParent(self) as RootStore
      const { api } = self.environment

      self.state = "IN_PROGRESS"
      const result = yield api.login(username, password)
      if (result) {
        self.state = "LOGGED_IN"
      } else {
        notificationStore.setNotification("login.error", "error")
        self.state = "LOGGED_OUT"
      }
    }),
    logout() {
      clear()
      self.environment.api.setup()
      self.state = "LOGGED_OUT"
    },
    setPassword: flow(function*(code: string, email: string, password: string) {
      const { notificationStore } = getParent(self) as RootStore
      const { api } = self.environment

      self.state = "IN_PROGRESS"
      const result = yield api.setPassword(code, email, password)
      self.state = "LOGGED_OUT"
      if (!result) notificationStore.setNotification("reset.error", "error")
      return result
    }),
  }))

/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type UserStoreType = Instance<typeof UserStoreModel>
export interface UserStore extends UserStoreType {}
type UserStoreSnapshotType = SnapshotOut<typeof UserStoreModel>
export interface UserStoreSnapshot extends UserStoreSnapshotType {}
