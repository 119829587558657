import React from "react"
import { observer } from "mobx-react"
import classNames from "classnames"
import { Drawer, List } from "@material-ui/core"
import DrawerHeader from "./DrawerHeader"
import { useSidebarStyles } from "./styles"
import { SidebarItem, Item } from "../nav"
import { ListItem } from "./ListItem"
import { RootPaths, createContentPath, createStoryPath } from "../../../routers/Paths"

import AddIcon from "../../../static/Add.svg"

interface SidebarProps {
  location?: Location
  open: boolean
  handleOpen(): void
  handleClose(): void
  menuItems: SidebarItem[]
}

const Sidebar = (props: SidebarProps) => {
  const classes = useSidebarStyles()
  const { open, handleOpen, handleClose, menuItems, location } = props

  const addItem = (): Item => {
    return {
      title: "add",
      icon: AddIcon,
      activeIcon: AddIcon,
      path: location?.pathname.includes(RootPaths.content)
        ? createContentPath("/uusi")
        : createStoryPath("/uusi"),
    }
  }

  const sendMessage = (): Item => {
    return {
      title: "sendGroupMessage",
      icon: AddIcon,
      activeIcon: AddIcon,
      path: RootPaths.sendGroupMessage,
    }
  }

  const renderItem = (item: Item) => {
    return <ListItem key={item.path} open={open} item={item} />
  }

  const renderMenuItems = () => {
    return menuItems.map(item => {
      return (
        <List component="div" key={item.header} disablePadding>
          {item.items.map(item => renderItem(item))}
        </List>
      )
    })
  }

  const renderMenu = () => {
    return (
      <div className={classes.menu}>
        <List component="nav" disablePadding>
          {renderMenuItems()}
        </List>
        <List>
          <ListItem open={open} item={addItem()} darkIcon={true} />
          <ListItem open={open} item={sendMessage()} darkIcon={true} />
        </List>
      </div>
    )
  }

  return (
    <Drawer
      variant="permanent"
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: classNames(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      open={open}
    >
      <DrawerHeader open={open} handleOpen={handleOpen} handleClose={handleClose} />
      {renderMenu()}
    </Drawer>
  )
}

export default observer(Sidebar)
