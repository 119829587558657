import React from "react"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import { useHeaderStyles } from "./styles"

import MoonaLogo from "../../../static/moona-logo 2.png"

interface DrawerHeaderProps {
  open: boolean
  handleOpen(): void
  handleClose(): void
}

const DrawerHeader = (props: DrawerHeaderProps) => {
  const { open, handleOpen, handleClose } = props
  const classes = useHeaderStyles()

  const renderContent = () => {
    return (
      <div
        className={classNames(classes.headerContainer, {
          [classes.headerOpen]: open,
          [classes.headerClose]: !open,
        })}
      >
        <img
          className={classNames(classes.logo, {
            [classes.logoOpen]: open,
            [classes.logoClose]: !open,
          })}
          src={MoonaLogo}
          alt="logo"
        />
        <div
          className={classNames(classes.fill, {
            [classes.fillOpen]: open,
            [classes.fillClose]: !open,
          })}
        >
          <div className={classes.closeButton}>
            <ChevronLeft className={classes.icon} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <Button
      className={classes.headerContainer}
      classes={{ root: classes.headerContainer }}
      onClick={open ? handleClose : handleOpen}
    >
      {renderContent()}
    </Button>
  )
}

export default DrawerHeader
