import i18next from "i18next"
import { EditorState, ContentState, convertToRaw } from "draft-js"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import { languages } from "../i18n"

/** Get current language */
export const getCurrentLang = () => {
  const availableLanguages = languages.map(({ value }) => value)

  if (i18next.language !== undefined) {
    const currentLang = i18next.language.substr(0, 2)
    if (availableLanguages.includes(currentLang)) {
      return currentLang
    }
  }
  return languages[0].value // Fallback to default lang
}

export const editorStateToHtml = (state: EditorState): string => {
  return draftToHtml(convertToRaw(state.getCurrentContent()))
}

export const htmlToEditorState = (html: string): EditorState => {
  const draftDescription = htmlToDraft(html)
  const { contentBlocks, entityMap } = draftDescription
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
  return EditorState.createWithContent(contentState)
}

/**
 * Check that password is strong enough
 * @param {string} password - User password
 */
export function isStrongPassword(password: string) {
  // Password must:
  // - contain at least 1 uppercase alphabetical character
  // - contain at least number or special character
  // - have at least 12 characters
  const pattern = /(?=.*[A-Z])(?=.*[0-9!@#$%^&*(),.?":{}|<>])(?=.{12,})/
  return password && pattern.test(password)
}
