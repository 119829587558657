import store from "store2"

/**
 * Loads a string from storage.
 *
 * @param key The key to fetch.
 */
export function load<T>(key: string): T | null {
  try {
    return store.get(key)
  } catch {
    process.env.NODE_ENV === "development" && console.log("error fetching from localstorage")
    return null
  }
}

/**
 * Saves a string to storage.
 *
 * @param key The key to fetch.
 * @param value The value to store.
 */
export function save(key: string, value: any): boolean {
  try {
    store.set(key, value)
    return true
  } catch {
    process.env.NODE_ENV === "development" && console.log("error saving to localstorage")
    return false
  }
}

/**
 * Removes something from storage.
 *
 * @param key The key to kill.
 */
export function remove(key: string): void {
  try {
    store.remove(key)
  } catch (e) {
    process.env.NODE_ENV === "development" && console.warn(e)
  }
}

/**
 * Burn it all to the ground.
 */
export function clear(): void {
  try {
    store.clear()
  } catch (e) {
    process.env.NODE_ENV === "development" && console.warn(e)
  }
}

export const LAYOUT_SIDEBAR_OPEN = "LAYOUT_SIDEBAR_OPEN"
