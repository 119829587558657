import React, { FC } from "react"
import { Switch, Route, BrowserRouter, Redirect, useLocation } from "react-router-dom"
import { AuthenticatedRouter } from "./AuthenticatedRouter"
import { useStores } from "../models/root-store"
import { observer } from "mobx-react"
import { LoginScreen, ResetPasswordScreen } from "../screens"
import { RootPaths, isAllowed } from "./Paths"

export const UnauthenticatedRedirect = observer(() => {
  const { pathname } = useLocation()
  const isAllowedPath = isAllowed(pathname)

  const { userStore } = useStores()
  const { isLoggedIn } = userStore
  if (!isLoggedIn && !isAllowedPath) {
    return <Redirect to={RootPaths.login} />
  }
  if (isLoggedIn && isAllowedPath) {
    return <Redirect to={RootPaths.index} />
  }
  return null
})

export const RootRouter: FC = observer(() => {
  const { userStore } = useStores()
  const { isLoggedIn } = userStore
  return (
    <BrowserRouter>
      <UnauthenticatedRedirect />
      <Switch>
        <Route path={RootPaths.resetPassword} exact>
          <ResetPasswordScreen />
        </Route>
        <Route path={RootPaths.login} exact>
          <LoginScreen />
        </Route>
        {isLoggedIn && <AuthenticatedRouter />}
      </Switch>
    </BrowserRouter>
  )
})
