import React, { useEffect } from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import { observer } from "mobx-react"
import {
  ContentViewsScreen,
  StoriesScreen,
  SingleArticleScreen,
  SendGroupMessageScreen,
} from "../screens"
import { Layout } from "../components/Layout"
import { RootPaths } from "./Paths"
import { useStores } from "../models/root-store"
import { useTranslation } from "react-i18next"

export const AuthenticatedRouter: React.FC = observer(() => {
  const { articleStore } = useStores()
  const { t } = useTranslation()

  useEffect(() => {
    const getArticles = async () => await articleStore.getArticles()
    getArticles()
  }, [articleStore])

  return (
    <Layout>
      <Switch>
        <Route exact path={RootPaths.index}>
          <Redirect to={RootPaths.content} />
        </Route>
        <Route path={RootPaths.content} exact>
          <ContentViewsScreen />
        </Route>
        <Route path={`${RootPaths.content}/uusi`} exact>
          <SingleArticleScreen category="generic" placeholderTitle={t("content.new")} />
        </Route>
        <Route path={`${RootPaths.content}/muokkaa/:id`}>
          <SingleArticleScreen category="generic" placeholderTitle={t("content.addTitle")} />
        </Route>
        <Route path={RootPaths.stories} exact>
          <StoriesScreen />
        </Route>
        <Route path={`${RootPaths.stories}/uusi`} exact>
          <SingleArticleScreen category="story" placeholderTitle={t("stories.new")} />
        </Route>
        <Route path={`${RootPaths.stories}/muokkaa/:id`}>
          <SingleArticleScreen category="story" placeholderTitle={t("stories.addTitle")} />
        </Route>
        <Route path={RootPaths.sendGroupMessage}>
          <SendGroupMessageScreen placeholderTitle={t("sendGroupMessage.new")} />
        </Route>
      </Switch>
    </Layout>
  )
})
