import React from "react"
import MenuItem from "@material-ui/core/MenuItem"
import Select, { SelectProps } from "@material-ui/core/Select"
import { makeStyles } from "@material-ui/styles"
import ArrowIcon from "@material-ui/icons/ArrowDropDown"
import { color } from "../theme/color"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    position: "relative",
  },
  input: {
    height: 50,
    fontSize: 16,
    color: color.text,
    backgroundColor: color.transparent,
    justifyContent: "center",
    borderRadius: 3,
    border: `1px solid ${color.line}`,
    padding: "0px 15px",
    "&::placeholder": {
      color: color.dim,
    },
  },
  menuItem: {
    fontSize: 18,
    backgroundColor: color.white,
    textTransform: "capitalize",
  },
  selectRoot: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    height: 40,
    backgroundColor: color.white,
    textTransform: "capitalize",
    "&:focus": {
      backgroundColor: color.white,
    },
  },
  selectDisabled: {
    color: color.dim,
    cursor: "not-allowed",
    fontSize: 16,
  },
  icon: {
    color: color.dim,
    zIndex: 10000,
    position: "absolute",
    top: 12,
    right: 16,
    pointerEvents: "none",
  },
  menuRoot: {
    maxHeight: "50%",
  },
  placeholder: {
    opacity: 0.5,
    color: color.dim,
  },
})

interface Option {
  name: string
  value: string
}

interface SelectInputProps {
  width?: string
  maxLength?: number
  max?: number
  min?: number
  options: Option[]
}

const SelectInput: React.FC<SelectInputProps & SelectProps> = props => {
  const { id, width, placeholder, value, onChange, disabled } = props
  const { t } = useTranslation()
  const classes = useStyles()
  const renderOptions = () =>
    props.options.map(option => (
      <MenuItem key={option.value} value={option.value} classes={{ root: classes.menuItem }}>
        {option.name}
      </MenuItem>
    ))

  const styles = width ? { width } : {}
  return (
    <div className={classes.container} style={styles}>
      {!disabled && <ArrowIcon className={classes.icon} />}
      <Select
        id={id}
        value={value}
        onChange={onChange}
        className={classes.input}
        classes={{
          select: `${classes.selectRoot} ${value === "" && classes.placeholder}`,
          disabled: classes.selectDisabled,
        }}
        IconComponent={undefined}
        disabled={disabled}
        displayEmpty
        disableUnderline
        MenuProps={{ MenuListProps: { classes: { root: classes.menuRoot } } }}
      >
        <MenuItem value="" classes={{ root: classes.menuItem }} disabled>
          {placeholder && t(placeholder)}
        </MenuItem>
        {renderOptions()}
      </Select>
    </div>
  )
}

export default SelectInput
