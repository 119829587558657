import Reactotron from "reactotron-react-js"
import { mst } from "reactotron-mst"

type Tron = {
  trackMstNode?: ((node?: any | undefined) => any) | undefined
  log?: ((message: any) => void) | undefined
}

export const reactotron: Tron = Reactotron.configure()
  .use(mst())
  .connect()
