import React from "react"
import { useTranslation } from "react-i18next"
import { TableHead, TableRow, TableCell } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { color } from "../../theme/"

const useStyles = makeStyles({
  root: {
    height: 50,
  },
  tableHead: {
    textAlign: "center",
    backgroundColor: color.palette.white,
    color: color.text,
    fontFamily: "Montserrat",
    fontSize: 18,
    fontWeight: 700,
    minWidth: 60,
    padding: 0,
    border: `1px solid ${color.lighterLine}`,
    borderTopWidth: 0,
    width: "22.5%",
    "&:first-child": {
      borderLeftWidth: 0,
      textAlign: "left",
      textIndent: 16,
      width: "45%",
    },
    "&:last-child": {
      borderRightWidth: 0,
      width: "10%",
    },
  },
})

interface TableHeaderProps {
  headerTitles: string[]
}

const TableHeader: React.FC<TableHeaderProps> = ({ headerTitles }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderHeaderTitles = () => {
    return headerTitles.length
      ? headerTitles.map(title => (
          <TableCell key={title} className={classes.tableHead}>
            {t(`headerTitle.${title}`)}
          </TableCell>
        ))
      : null
  }

  return (
    <TableHead>
      <TableRow classes={{ root: classes.root }}>
        {renderHeaderTitles()}
        <TableCell className={classes.tableHead}>{t("headerTitle.edit")}</TableCell>
      </TableRow>
    </TableHead>
  )
}

export default TableHeader
