import { RootPaths } from "../../routers/Paths"
import Reader from "../../static/Reader.svg"
import Face from "../../static/Face.svg"

export interface SidebarItem {
  header: string
  items: Item[]
}

export interface Item {
  title: string
  icon: string
  activeIcon: string
  path: string
}

export const SidebarItems = [
  {
    header: "general",
    items: [
      {
        title: "content",
        icon: Reader,
        activeIcon: Reader,
        path: RootPaths.content,
      },
      {
        title: "stories",
        icon: Face,
        activeIcon: Face,
        path: RootPaths.stories,
      },
    ],
  },
]
