import React from "react"
import { observer } from "mobx-react"
import { makeStyles } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { color } from "../theme"

const useStyles = makeStyles({
  container: {
    display: "flex",
    flex: 1,
    flexFlow: "column nowrap",
  },
  headerContainer: {
    background: color.white,
    width: "100%",
    display: "flex",
  },
  header: {
    fontSize: 44,
    fontWeight: "normal",
    margin: "30px 50px",
  },
})

interface ScreenProps {
  header: string
}

const Screen: React.FC<ScreenProps> = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <h2 className={classes.header}>{t(props.header)}</h2>
      </div>
      {props.children}
    </div>
  )
}

export default observer(Screen)
