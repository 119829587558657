import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { color } from "../theme"
import { useTranslation } from "react-i18next"
import Button from "./Button"
import Modal from "@material-ui/core/Modal"

function getModalStyle() {
  const top = 40
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  container: {
    width: 426,
    minHeight: 200,
    position: "absolute",
    backgroundColor: color.white,
    border: `1px solid ${color.lighterLine}`,
    padding: "30px 40px",
    display: "flex",
    flexFlow: "column nowrap",
    flex: "0 1 auto",
    boxShadow: theme.shadows[5],
    "&:focus": {
      outline: "none",
    },
  },
  header: {
    fontSize: 24,
    fontWeight: "bold",
    marginTop: 0,
    marginBottom: 10,
    fontFamily: "Montserrat",
  },
  textContainer: {
    fontFamily: "Montserrat",
    marginBottom: 25,
  },
  buttonContainer: {
    display: "flex",
  },
  button: {
    marginRight: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: 160,
    minHeight: 50,
  },
  buttonText: {
    fontSize: 18,
    fontFamily: "Montserrat",
    transition: "150ms all",
  },
  cancelButton: {},
  cancelText: {
    marginRight: 24,
  },
  progress: {
    color: color.white,
  },
}))

interface DeleteModalProps {
  isOpen: boolean
  handleClose(): void
  handleDelete(): void
}

const DeleteModal: React.FC<DeleteModalProps> = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { isOpen, handleClose, handleDelete } = props
  const [modalStyle] = React.useState(getModalStyle)

  const onDelete = () => {
    handleDelete()
  }

  const onCancel = () => {
    handleClose()
  }

  const renderContent = () => {
    return (
      <div style={modalStyle} className={classes.container}>
        <h3 className={classes.header}>{t("modal.deletePublishing")}</h3>
        <p className={classes.textContainer}>{t("modal.infoText")}</p>
        <div className={classes.buttonContainer}>
          <Button className={classes.button} onClick={onCancel} secondary>
            <span className={classes.buttonText}>{t("common.cancel")}</span>
          </Button>
          <Button
            className={classes.button}
            style={{ backgroundColor: color.palette.red }}
            onClick={onDelete}
          >
            <span className={classes.buttonText}>{t("common.delete")}</span>
          </Button>
        </div>
      </div>
    )
  }
  return (
    <Modal open={isOpen} onClose={handleClose}>
      {renderContent()}
    </Modal>
  )
}

export default DeleteModal
