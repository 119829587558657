import React from "react"
import { makeStyles, CircularProgress } from "@material-ui/core"
import { color } from "../../theme"
import Button from "../Button"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles({
  container: {
    backgroundColor: color.white,
    border: `1px solid ${color.lighterLine}`,
    padding: "24px 30px",
    display: "flex",
    flexFlow: "column nowrap",
    flex: "0 1 auto",
  },
  header: {
    fontSize: 24,
    fontWeight: "bold",
    marginTop: 0,
  },
  buttonContainer: {
    display: "flex",
  },
  button: {
    marginRight: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: 160,
    minHeight: 50,
  },
  buttonText: {
    fontSize: 18,
    fontFamily: "Montserrat",
    transition: "150ms all",
  },
  cancelButton: {},
  cancelText: {
    marginRight: 24,
  },
  progress: {
    color: color.white,
  },
})

interface PublishProps {
  onSubmit(): void
  onCancel(): void
  update: boolean
  disabled?: boolean
  loading?: boolean
  className?: string
}

export const Publish: React.FC<PublishProps> = props => {
  const { onSubmit, onCancel, disabled, loading, update, className } = props
  const { t } = useTranslation()
  const classes = useStyles()

  const renderButtonContent = () => {
    if (loading) return <CircularProgress classes={{ root: classes.progress }} />
    return <span className={classes.buttonText}>{t(update ? "form.update" : "form.publish")}</span>
  }
  return (
    <div className={className}>
      <div className={classes.container}>
        <h3 className={classes.header}>{t("form.publishing")}</h3>
        <div className={classes.buttonContainer}>
          <Button className={classes.button} disabled={disabled} onClick={onSubmit}>
            {renderButtonContent()}
          </Button>
          <Button className={classes.button} onClick={onCancel} secondary>
            <span className={classes.buttonText}>{t("common.cancel")}</span>
          </Button>
        </div>
      </div>
    </div>
  )
}
