import React from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import { ListItem as MUIListItem, ListItemIcon, ListItemText } from "@material-ui/core"
import classNames from "classnames"
import { useSidebarStyles } from "./styles"
import { Item } from "../nav"
import { ListLink } from "./ListLink"

interface ListItemProps {
  item: Item
  darkIcon?: boolean
  open: boolean
}

export const ListItem: React.FC<ListItemProps> = ({ item, darkIcon, open }) => {
  const classes = useSidebarStyles()
  const { t } = useTranslation()
  const location = useLocation()

  const isCurrentPage = (itemPath: string) => location.pathname.includes(itemPath)

  return (
    <MUIListItem
      key={item.title}
      classes={{ root: darkIcon ? classes.lastItem : classes.listItem }}
      className={open ? classes.listItemOpen : classes.listItemClose}
      disableGutters
      button
    >
      <ListLink to={item.path}>
        <ListItemText
          className={classNames(classes.listItemText, {
            [classes.listItemTextOpen]: open,
            [classes.listItemTextClose]: !open,
          })}
          classes={{
            primary: classNames(classes.listItemText, {
              [classes.listItemTextOpen]: open,
              [classes.listItemTextClose]: !open,
            }),
          }}
        >
          {t(`sidebar.${item.title}`)}
        </ListItemText>
        <ListItemIcon
          classes={{
            root: classNames(classes.listIcon, {
              [classes.listIconActive]: isCurrentPage(item.path),
              [classes.listIconDark]: darkIcon,
            }),
          }}
        >
          <img src={item.icon} alt={t(item.title)} className={classes.icon} />
        </ListItemIcon>
      </ListLink>
    </MUIListItem>
  )
}
