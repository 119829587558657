import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { makeStyles } from "@material-ui/styles"
import { FormControlLabel, Checkbox } from "@material-ui/core"
import { observer } from "mobx-react"
import { color } from "../theme"
import { Input, Button } from "../components"
import { useStores } from "../models/root-store"
import Snackbar from "../components/Layout/Snackbar"

import BackgroundImage from "../static/LoginBackground.png"
import Logo from "../static/MOONA_pallologo_keltainen 1.png"
import Korento from "../static/Logo-white-potilasjarjesto 1.png"

const useStyles = makeStyles({
  root: {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: color.palette.white,
  },
  loginContainer: {
    flex: 0.5,
    padding: "60px 80px",
  },
  header: {
    fontSize: 64,
    fontWeight: "normal",
    textTransform: "uppercase",
    color: color.text,
  },
  secondary: {
    fontSize: 18,
    color: color.text,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  label: {
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: 24,
    textIndent: 15,
    marginTop: 40,
    marginBottom: 10,
    color: color.palette.semiBlack,
  },
  input: {
    height: 70,
    fontSize: 20,
    backgroundColor: color.palette.offWhite,
    fontFamily: "Montserrat",
    "&:placeholder": {},
  },
  formBottom: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
    width: "100%",
  },
  checkbox: {
    marginRight: 15,
    "& >": {
      width: 30,
      height: 30,
    },
  },
  checkboxLabel: {
    fontFamily: "Montserrat",
    fontSize: 20,
    color: color.palette.semiBlack,
  },
  button: {
    marginTop: 40,
  },
  buttonContent: {
    fontSize: 24,
    textTransform: "uppercase",
    margin: "5px 50px",
    fontWeight: 400,
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  horizontal: {
    flexDirection: "row",
  },
  newsContainer: {
    position: "relative",
    display: "flex",
    flex: 0.5,
    flexFlow: "column nowrap",
    justifyContent: "flex-end",
    backgroundPosition: "bottom",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${BackgroundImage})`,
  },
  overlay: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: color.palette.paleYellow,
    zIndex: 1,
    opacity: 0.666,
  },
  logoContainer: {
    zIndex: 3,
    display: "flex",
    flex: 0.8,
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo: {
    width: "60%",
  },
  korento: {
    width: "20%",
  },
})

interface LoginScreenProps {}

const LoginScreen: React.ComponentType<LoginScreenProps> = observer(() => {
  const { userStore } = useStores()
  const classes = useStyles()
  const { t } = useTranslation()
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [rememberMe, setRememberMe] = useState(true)
  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setUsername(e.target.value)
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(e.target.value)

  const handleLogin = () => {
    if (username.length && password.length) {
      userStore.login(username, password)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault()
      e.stopPropagation()
      handleLogin()
    }
  }

  const checkboxClicked = (_: any, value: boolean) => {
    setRememberMe(value)
  }

  const formBottom = () => {
    return (
      <div className={classes.formBottom}>
        <div className={classes.horizontal}>
          <FormControlLabel
            classes={{ label: classes.checkboxLabel }}
            control={<Checkbox checked={rememberMe} onChange={checkboxClicked} />}
            label={t("login.rememberMe")}
          />
        </div>
        <Button
          disabled={!username || !password}
          className={classes.button}
          onClick={handleLogin}
          loading={userStore.state === "IN_PROGRESS"}
        >
          <p className={classes.buttonContent}>{t("login.signIn")}</p>
        </Button>
      </div>
    )
  }

  const renderForm = () => {
    return (
      <div className={classes.formContainer}>
        <p className={classes.label}>{t("login.username")}</p>
        <Input
          className={classes.input}
          placeholder={t("login.username")}
          value={username}
          onChange={handleUsernameChange}
          onKeyDown={handleKeyDown}
        />
        <p className={classes.label}>{t("login.password")}</p>
        <Input
          className={classes.input}
          type="password"
          placeholder={t("login.password")}
          value={password}
          onChange={handlePasswordChange}
          onKeyDown={handleKeyDown}
        />
        {formBottom()}
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.loginContainer}>
        <h1 className={classes.header}>{t("login.header")}</h1>
        {/* TODO: Use translated text */}
        {/* <p className={classes.secondary}>
          Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Nullam id dolor id
          nibh ultricies vehicula ut id elit. Nullam quis risus eget urna mollis ornare vel eu leo.
          Nullam id dolor id nibh ultricies vehicula ut id elit.
        </p> */}
        {renderForm()}
        <Snackbar />
      </div>

      <div className={classes.newsContainer}>
        <div className={classes.overlay} />
        <div className={classes.logoContainer}>
          <img className={classes.logo} src={Logo} alt="logo" />
          <img className={classes.korento} src={Korento} alt="logo" />
        </div>
      </div>
    </div>
  )
})

export default LoginScreen
