import { Theme, darken } from "@material-ui/core"
import { color } from "../../../theme"
import { makeStyles } from "@material-ui/styles"

export const useSidebarStyles = makeStyles((theme: Theme) => ({
  listItem: {
    height: 60,
    padding: 0,
    transition: "200ms all",
    borderBottom: `1px solid ${color.dimmerWhite}`,
  },
  lastItem: {
    height: 60,
    padding: 0,
    transition: "200ms all",
  },
  listItemOpen: {
    padding: "0 20px",
  },
  listItemClose: {
    padding: "0 10px",
    overflowX: "hidden",
  },
  listIcon: {
    height: 40,
    width: 40,
    minWidth: 40,
    maxWidth: 40,
    alignItems: "center",
    justifyContent: "center",
    color: color.white,
    margin: 0,
    backgroundColor: color.dimmerWhite,
    transition: "200ms all",
  },
  listIconDark: {
    backgroundColor: color.palette.darkGrey,
  },
  listIconActive: {
    backgroundColor: color.dimWhite,
  },
  icon: {
    height: 20,
    width: 20,
  },
  listItemText: {
    fontSize: 16,
    fontWeight: 700,
    color: color.white,
    fontFamily: "Montserrat",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    display: "block",
  },
  listItemTextOpen: {
    width: 260,
  },
  listItemTextClose: {
    width: 0,
    flex: 0,
    opacity: 0,
  },
  drawer: {
    width: 364,
    flexShrink: 0,
    whiteSpace: "nowrap",
    zIndex: 99,
    background: color.primary,
    border: "none",
    overflowX: "hidden",
  },
  drawerOpen: {
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: 364,
  },
  drawerClose: {
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 60,
  },
  link: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  arrow: {
    transform: "rotate(-90deg)",
  },
  menu: {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    flex: 1,
  },
}))

export const useHeaderStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    zIndex: 9999,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    height: 60,
    backgroundColor: color.primary,
    padding: 0,
    borderRadius: 0,
    borderBottom: `1px solid ${color.dimmerWhite}`,
    "&:hover": {
      background: darken(color.primary, 0.1),
    },
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  headerOpen: {
    width: 364,
  },
  headerClose: {
    width: 60,
  },
  contentContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    position: "relative",
  },
  icon: {
    color: color.white,
  },
  logo: {
    position: "relative",
    objectFit: "contain",
    right: 0,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  logoOpen: {
    width: 136,
    padding: "0 20px",
  },
  logoClose: {
    width: 60,
    padding: "0 3px",
  },
  fill: {
    display: "flex",
    justifyContent: "flex-end",
    minWidth: 0,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  fillOpen: {
    flex: 1,
    width: 220,
    paddingRight: 20,
    opacity: 1,
  },
  fillClose: {
    flex: 0,
    width: 0,
    paddingRight: 0,
    opacity: 0,
    "& > *": {
      minWidth: 0,
      width: 0,
    },
  },
  closeButton: {
    width: 40,
    height: 40,
    borderRadius: 20,
    background: color.palette.darkGrey,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}))
