import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { UserStoreModel, NotificationStore } from "../user-store"
import { ArticleStore, LanguageOption } from "../article-store"
import { GroupMessageStore } from "../groupMessage-store"

/**
 * A RootStore model.
 */
export const RootStoreModel = types.model("RootStore").props({
  notificationStore: types.optional(NotificationStore, {}),
  userStore: types.optional(UserStoreModel, { state: "LOGGED_OUT" }),
  articleStore: types.optional(ArticleStore, {
    currentLanguage: LanguageOption.create({ name: "fin", value: "fin" }),
  }),
  groupMessageStore: types.optional(GroupMessageStore, {}),
})

/**
 * The RootStore instance.
 */
export type RootStore = Instance<typeof RootStoreModel>

/**
 * The data of a RootStore.
 */
export type RootStoreSnapshot = SnapshotOut<typeof RootStoreModel>
