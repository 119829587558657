import React, { FC, useEffect, useState } from "react"
import { observer } from "mobx-react"
import { makeStyles } from "@material-ui/core"
import { EditorState } from "draft-js"
import { useParams, useHistory } from "react-router-dom"
import { ArticleForm, Screen, Publish } from "../components"
import { useStores } from "../models/root-store"
import { Categories } from "../utils/constants"
import { editorStateToHtml, htmlToEditorState } from "../utils"
import { color } from "../theme"
import { ArticleLanguage } from "../utils/interfaces"

export const useStyles = makeStyles({
  container: {
    padding: 50,
    display: "flex",
    flex: 1,
  },
  buttonContent: {
    fontSize: 18,
    textTransform: "capitalize",
    padding: "0px 30px",
    margin: 0,
  },
  form: {
    display: "flex",
    flex: 1,
  },
  separator: {
    borderRight: `1px solid ${color.lighterLine}`,
    width: 1,
    margin: "0 50px",
  },
  publish: {},
})

interface Props {
  category: Categories
  placeholderTitle: string
}

export const SingleArticleScreen: FC<Props> = observer(props => {
  const { category, placeholderTitle } = props
  const { id } = useParams<{ id: string }>()
  const { articleStore } = useStores()
  const { selectedArticle, currentLanguage, fetching, getArticleById, clearSelected } = articleStore
  const classes = useStyles()
  const history = useHistory()

  const [title, setTitle] = useState("")
  const [text, setText] = useState(EditorState.createEmpty())

  const languageCheck = () => {
    if (
      articleStore.selectedArticle &&
      articleStore.selectedArticle?.language !== articleStore.currentLanguage.name
    ) {
      history.goBack()
    }
  }

  useEffect(() => {
    const getArticle = async (id: number) => await getArticleById(id)
    if (id) getArticle(Number(id))
    return () => {
      setTitle("")
      setText(EditorState.createEmpty())
      clearSelected()
    }
  }, [id])

  useEffect(() => {
    languageCheck()
    if (selectedArticle && !fetching) {
      setTitle(selectedArticle.title)
      if (selectedArticle.text) setText(htmlToEditorState(selectedArticle.text))
    } else if (!selectedArticle && !fetching) {
      setTitle("")
      setText(EditorState.createEmpty())
    }
  }, [articleStore, selectedArticle, fetching, currentLanguage])

  const isDisabled = () => {
    return !title.length || !text.getCurrentContent().hasText() || articleStore.fetching
  }

  const handleSubmit = async () => {
    const res = await articleStore.submit(
      {
        title,
        text: editorStateToHtml(text),
        category,
        language: articleStore.currentLanguage.name as ArticleLanguage,
      },
      Number(id)
    )
    if (res && !id) {
      setTitle("")
      setText(EditorState.createEmpty())
    }
  }

  const handleCancel = () => history.goBack()

  return (
    <Screen header={title || placeholderTitle}>
      <div className={classes.container}>
        <ArticleForm
          className={classes.form}
          title={title}
          setTitle={setTitle}
          editorState={text}
          setEditorState={setText}
        />
        <div className={classes.separator} />
        <Publish
          update={Boolean(id)}
          loading={!!articleStore.fetching}
          disabled={isDisabled()}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      </div>
    </Screen>
  )
})

export default SingleArticleScreen
