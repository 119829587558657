import React from "react"
import {
  TableRow,
  TableFooter as MUITableFooter,
  TablePagination,
  IconButton,
} from "@material-ui/core"
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons"
import { makeStyles } from "@material-ui/styles"
import { color } from "../../theme"

const useActionsStyles = makeStyles({
  actionsRoot: {
    marginTop: 30,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  leftIconButton: {
    padding: 0,
    height: 40,
    width: 40,
    border: `1px solid ${color.line}`,
    borderRight: "none",
    borderRadius: 3,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  rightIconButton: {
    padding: 0,
    height: 40,
    width: 40,
    border: `1px solid ${color.line}`,
    borderRadius: 3,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  icon: {
    color: color.text,
  },
  disabledIcon: {
    color: color.dim,
  },
})

interface TablePaginationActionsProps {
  onChangePage(event: any, toPage: number): void
  page: number
  count: number
  rowsPerPage: number
}

const TablePaginationActions: React.FC<TablePaginationActionsProps> = props => {
  const classes = useActionsStyles()
  const { onChangePage, page, count, rowsPerPage } = props

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onChangePage(event, page - 1)
  }

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onChangePage(event, page + 1)
  }

  const isNextDisabled = () => page >= Math.ceil(count / rowsPerPage) - 1

  const leftArrowClasses = { root: page !== 0 ? classes.icon : classes.disabledIcon }
  const rightArrowClasses = { root: isNextDisabled() ? classes.disabledIcon : classes.icon }
  return (
    <div className={classes.actionsRoot}>
      <IconButton
        className={classes.leftIconButton}
        onClick={handleBackButtonClick}
        disabled={page === 0}
      >
        <KeyboardArrowLeft classes={leftArrowClasses} />
      </IconButton>
      <IconButton
        className={classes.rightIconButton}
        onClick={handleNextButtonClick}
        disabled={isNextDisabled()}
      >
        <KeyboardArrowRight classes={rightArrowClasses} />
      </IconButton>
    </div>
  )
}

interface TableFooterProps {
  total: number
  page: number
  pageSize: number
  onChangePage(event: any, toPage: number): void
}

const TableFooter: React.FC<TableFooterProps> = props => {
  const { total, page, pageSize, onChangePage } = props
  return (
    <MUITableFooter component="div">
      <TableRow component="div">
        <TablePagination
          rowsPerPageOptions={[]}
          count={total}
          rowsPerPage={pageSize}
          page={page}
          SelectProps={{ native: true }}
          onChangePage={onChangePage}
          ActionsComponent={TablePaginationActions}
          labelDisplayedRows={() => null}
          component="div"
        />
      </TableRow>
    </MUITableFooter>
  )
}

export default TableFooter
