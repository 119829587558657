import React, { FC } from "react"
import { Link } from "react-router-dom"
import { makeStyles } from "@material-ui/core"
import { color } from "../../../theme"

const useStyles = makeStyles({
  link: {
    color: color.white,
    fontWeight: 700,
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    textDecoration: "none",
    outline: "none",
    height: "100%",
    width: "100%",
    "&:hover": {
      textDecoration: "none",
    },
  },
})

export interface ListLinkProps {
  to: string
}

export const ListLink: FC<ListLinkProps> = props => {
  const classes = useStyles()
  return (
    <Link className={classes.link} to={props.to}>
      {props.children}
    </Link>
  )
}
