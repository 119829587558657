import React, { FC, useState } from "react"
import { observer } from "mobx-react"
import { EditorState } from "draft-js"
import { useHistory } from "react-router-dom"
import { ArticleForm, Screen, Send } from "../components"
import { useStores } from "../models/root-store"
import { editorStateToHtml } from "../utils"
import { useStyles } from "./SingleArticleScreen"

interface Props {
  placeholderTitle: string
}

export const SendGroupMessageScreen: FC<Props> = observer(props => {
  const { placeholderTitle } = props
  const { groupMessageStore } = useStores()
  const classes = useStyles()
  const history = useHistory()

  const [title, setTitle] = useState("")
  const [text, setText] = useState(EditorState.createEmpty())

  const isDisabled = () => {
    return !title.length || !text.getCurrentContent().hasText() || groupMessageStore.fetching
  }

  const handleSend = async () => {
    const res = await groupMessageStore.sendGroupMessage({
      title,
      text: editorStateToHtml(text),
    })
    if (res) {
      setTitle("")
      setText(EditorState.createEmpty())
    }
  }

  const handleCancel = () => history.goBack()

  return (
    <Screen header={placeholderTitle}>
      <div className={classes.container}>
        <ArticleForm
          className={classes.form}
          title={title}
          setTitle={setTitle}
          editorState={text}
          setEditorState={setText}
        />
        <div className={classes.separator} />
        <Send
          loading={!!groupMessageStore.fetching}
          disabled={isDisabled()}
          onSubmit={handleSend}
          onCancel={handleCancel}
        />
      </div>
    </Screen>
  )
})

export default SendGroupMessageScreen
