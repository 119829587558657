import React, { useState } from "react"
import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { makeStyles } from "@material-ui/styles"
import { Fade } from "@material-ui/core"
import qs from "query-string"
import { observer } from "mobx-react"
import { color } from "../theme"
import { Input, Button } from "../components"
import { useStores } from "../models/root-store"
import Snackbar from "../components/Layout/Snackbar"
import { isStrongPassword } from "../utils"
import { joinClassnames } from "../utils/joinClassnames"

import BackgroundImage from "../static/LoginBackground.png"
import Logo from "../static/MOONA_pallologo_keltainen 1.png"
import Korento from "../static/Logo-white-potilasjarjesto 1.png"

const useStyles = makeStyles({
  root: {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: color.palette.white,
  },
  loginContainer: {
    flex: 0.5,
    padding: "60px 80px",
    position: "relative",
  },
  header: {
    fontSize: 64,
    fontWeight: "normal",
    textTransform: "uppercase",
    color: color.text,
  },
  alignCenter: {
    textAlign: "center",
  },
  secondary: {
    fontSize: 18,
    color: color.text,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  label: {
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: 24,
    textIndent: 15,
    marginTop: 40,
    marginBottom: 10,
    color: color.palette.semiBlack,
  },
  input: {
    height: 70,
    fontSize: 20,
    backgroundColor: color.palette.offWhite,
    fontFamily: "Montserrat",
    "&:placeholder": {},
  },
  formBottom: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
    width: "100%",
  },
  checkbox: {
    marginRight: 15,
    "& >": {
      width: 30,
      height: 30,
    },
  },
  checkboxLabel: {
    fontFamily: "Montserrat",
    fontSize: 20,
    color: color.palette.semiBlack,
  },
  button: {
    marginTop: 40,
  },
  buttonContent: {
    fontSize: 24,
    textTransform: "uppercase",
    margin: "5px 50px",
    fontWeight: 400,
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  horizontal: {
    flexDirection: "row",
  },
  newsContainer: {
    position: "relative",
    display: "flex",
    flex: 0.5,
    flexFlow: "column nowrap",
    justifyContent: "flex-end",
    backgroundPosition: "bottom",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${BackgroundImage})`,
  },
  overlay: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: color.palette.paleYellow,
    zIndex: 1,
    opacity: 0.666,
  },
  logoContainer: {
    zIndex: 3,
    display: "flex",
    flex: 0.8,
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo: {
    width: "60%",
  },
  korento: {
    width: "20%",
  },
  done: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: color.white,
    display: "flex",
    flex: 1,
    flexDirection: "column",
    zIndex: 999,
    justifyContent: "center",
    alignItems: "center",
  },
})

interface Props {}

const ResetPasswordScreen: React.ComponentType<Props> = observer(() => {
  const location = useLocation()
  const values = qs.parse(location.search)

  const { userStore } = useStores()
  const classes = useStyles()
  const { t } = useTranslation()
  const [password1, setPassword1] = useState("")
  const [password2, setPassword2] = useState("")
  const [done, setDone] = useState(false)
  const handlePassword1Change = (e: React.ChangeEvent<HTMLInputElement>) =>
    setPassword1(e.target.value)
  const handlePassword2Change = (e: React.ChangeEvent<HTMLInputElement>) =>
    setPassword2(e.target.value)

  const isDisabled = () =>
    !password1 ||
    !password2 ||
    password1 !== password2 ||
    !isStrongPassword(password1) ||
    !values ||
    !values.email ||
    !values.email.length ||
    !values.code ||
    !values.code.length ||
    done

  const handleLogin = async () => {
    if (!isDisabled()) {
      const res = await userStore.setPassword(
        values.code as string,
        decodeURIComponent(values.email as string),
        password1
      )
      if (res) setDone(true)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault()
      e.stopPropagation()
      handleLogin()
    }
  }

  const formBottom = () => {
    return (
      <div className={classes.formBottom}>
        <Button
          disabled={isDisabled()}
          className={classes.button}
          onClick={handleLogin}
          loading={userStore.fetching}
        >
          <p className={classes.buttonContent}>{t("reset.accept")}</p>
        </Button>
      </div>
    )
  }

  const renderForm = () => {
    return (
      <div className={classes.formContainer}>
        <p className={classes.label}>{t("reset.password")}</p>
        <Input
          className={classes.input}
          type="password"
          placeholder={t("reset.password")}
          value={password1}
          onChange={handlePassword1Change}
          onKeyDown={handleKeyDown}
        />
        <p className={classes.label}>{t("reset.repeatPassword")}</p>
        <Input
          className={classes.input}
          type="password"
          placeholder={t("reset.repeatPassword")}
          value={password2}
          onChange={handlePassword2Change}
          onKeyDown={handleKeyDown}
        />
        {formBottom()}
      </div>
    )
  }

  const renderDone = () => {
    if (!done) return null
    return (
      <Fade in={done}>
        <div className={classes.done}>
          <h1 className={joinClassnames(classes.header, classes.alignCenter)}>
            {t("reset.success1")}
          </h1>
          <p className={classes.alignCenter}>{t("reset.success2")}</p>
        </div>
      </Fade>
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.loginContainer}>
        {renderDone()}
        <h1 className={classes.header}>{t("reset.header")}</h1>
        <p>{t("reset.instructions")}</p>
        {renderForm()}
        <Snackbar />
      </div>

      <div className={classes.newsContainer}>
        <div className={classes.overlay} />
        <div className={classes.logoContainer}>
          <img className={classes.logo} src={Logo} alt="logo" />
          <img className={classes.korento} src={Korento} alt="logo" />
        </div>
      </div>
    </div>
  )
})

export default ResetPasswordScreen
