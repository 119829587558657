export const palette = {
  black: "#000000",
  white: "#ffffff",
  grey1: "#4D4D4F",
  darkGrey: "#4F4F4F",
  grey2: "#939AA4",
  grey3: "#828282",
  grey4: "#B8B5B5",
  grey5: "#E5E5E5",
  grey6: "#F2F2F2",
  offWhite: "#F7F7F7",
  angry: "#dd3333",
  coral: "#FC696E",
  redDarker: "#A91425",
  redDarkest: "#B20521",
  redLighter: "#B64F5B",
  redAlternate: "#B51730",
  redAlternateDarker: "#991222",
  dimmedGrey: "rgba(77, 77, 79, 0.5)",
  dimmerGrey: "rgba(77, 77, 79, 0.3)",
  dimmedWhite: "rgba(255, 255, 255, 0.5)",
  dimmerWhite: "rgba(255, 255, 255, 0.2)",
  semiBlack: "rgba(0, 0, 0, 0.7)",
  paleYellow: "#F9ECC2",
  red: "#BB4242",
}
