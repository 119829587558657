import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { TableCell, TableRow as MUITableRow, IconButton } from "@material-ui/core"
import { Edit, Delete } from "@material-ui/icons"
import { makeStyles } from "@material-ui/styles"
import { color } from "../../theme"
import DeleteModal from "../DeleteModal"
import { useStores } from "../../models/root-store"
import { Article } from "../../utils/interfaces"

const useStyles = makeStyles({
  root: {
    backgroundColor: color.white,
    height: 50,
    cursor: "pointer",
    transition: "all 150ms",
  },
  hover: {
    "&$hover:hover": {
      backgroundColor: color.palette.grey6,
    },
  },
  cell: {
    fontSize: 16,
    fontFamily: "Montserrat",
    color: color.text,
    textAlign: "center",
    border: `1px solid ${color.lighterLine}`,
    borderBottomWidth: 0,
    "&:first-child": {
      borderLeftWidth: 0,
      textAlign: "left",
      textIndent: 16,
    },
    "&:last-child": {
      borderRightWidth: 0,
    },
  },
  editBtn: {
    padding: 0,
    width: 40,
    height: 40,
    borderRadius: 20,
  },
})

interface TableRowProps {
  item: Record<string, any>
  toSingle?(itemId: number): void
  keys: string[]
}

const TableRow: React.FC<TableRowProps> = props => {
  const { articleStore } = useStores()
  const classes = useStyles()
  const { t } = useTranslation()
  const { item, toSingle, keys } = props
  const [openModal, setOpenModal] = useState(false)

  const openDeleteModal = () => setOpenModal(true)

  const handleClose = () => {
    setOpenModal(false)
  }

  const handleDelete = () => {
    handleClose()
    articleStore.delete(item as Article)
  }

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    if (toSingle) toSingle(item.id)
  }

  const renderCells = () => {
    if (item) {
      return keys.map(k => (
        <TableCell key={k} className={classes.cell}>
          {item[k]}
        </TableCell>
      ))
    }
    return null
  }

  return (
    <MUITableRow classes={{ root: classes.root, hover: classes.hover }} hover>
      {renderCells()}
      <TableCell className={classes.cell}>
        <IconButton onClick={handleClick} className={classes.editBtn} aria-label={t("common.edit")}>
          <Edit />
        </IconButton>
        <IconButton
          onClick={openDeleteModal}
          className={classes.editBtn}
          aria-label={t("common.delete")}
        >
          <Delete />
        </IconButton>
      </TableCell>
      <DeleteModal
        isOpen={openModal}
        handleClose={handleClose}
        handleDelete={handleDelete}
      ></DeleteModal>
    </MUITableRow>
  )
}

export default TableRow
