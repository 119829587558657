export const RootPaths = {
  index: "/",
  content: "/sisaltonakymat",
  stories: "/tarinat",
  login: "/login",
  resetPassword: "/vaihda-salasana",
  sendGroupMessage: "/laheta-ryhmaviesti",
} as const

const UnauthenticatedPaths = [RootPaths.login, RootPaths.resetPassword]
export const isAllowed = (pathname: string) =>
  Boolean(UnauthenticatedPaths.map(p => pathname.endsWith(p)).filter(t => t).length)

export const ContentPaths = {
  list: "/",
  createStory: "/uusi",
  update: "/muokkaa",
} as const

export const StoryPaths = {
  list: "/",
  createStory: "/uusi",
  update: "/muokkaa",
} as const

type ValueOf<T> = T[keyof T]

export type RootPath = ValueOf<typeof RootPaths>
export type ContentPath = ValueOf<typeof ContentPaths>
export type StoryPath = ValueOf<typeof StoryPaths>

export type RouterPath = RootPath | ContentPath | StoryPath
export const joinPaths = (...paths: RouterPath[]) => paths.join("")

export const createContentPath = (path: ContentPath, contentId?: number) => {
  if (path === "/muokkaa") {
    return `${RootPaths.content}${path}/${contentId}`
  }
  return joinPaths(RootPaths.content, path)
}

export const createStoryPath = (path: StoryPath, storyId?: number) => {
  if (path === "/muokkaa") {
    return `${RootPaths.stories}${path}/${storyId}`
  }
  return joinPaths(RootPaths.stories, path)
}
