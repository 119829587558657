import { delay } from "../../utils/delay"
import { Instance, types, flow } from "mobx-state-tree"

export const NotificationTypes = [
  "success" as const,
  "error" as const,
  "info" as const,
  "warning" as const,
]

export type NotificationType = typeof NotificationTypes[number]

export const NotificationStore = types
  .model("NotificationStore")
  .props({
    message: types.maybe(types.string),
    type: types.maybe(types.enumeration(NotificationTypes)),
  })
  .actions(self => {
    return {
      clear() {
        self.message = undefined
        self.type = undefined
      },
    }
  })
  .actions(self => {
    return {
      setNotification: flow(function*(
        messageKey: string,
        type: NotificationType = "info",
        time = 3000
      ) {
        self.message = messageKey
        self.type = type
        yield delay(time)
        self.clear()
      }),
    }
  })

export interface NotificationStore extends Instance<typeof NotificationStore> {}
