import React from "react"
import { Button as MButton, CircularProgress, Fade } from "@material-ui/core/"
import { ButtonProps as MButtonProps } from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/styles"
import { color } from "../theme"
import { joinClassnames } from "../utils/joinClassnames"

const useStyles = makeStyles({
  root: {
    backgroundColor: color.primary,
    color: color.white,
    fontWeight: 400,
    borderRadius: 0,
    height: "auto",
    transition: "all 250ms",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "normal",
    "&:hover": {
      backgroundColor: color.primary,
      opacity: 0.666,
    },
  },
  secondary: {
    backgroundColor: color.palette.grey5,
    color: color.text,
    "&:hover": {
      backgroundColor: color.palette.grey4,
    },
  },
  loadingContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: color.primary,
    borderRadius: 50,
  },
  circularProgress: {
    color: color.white,
  },
})

interface ButtonProps {
  loading?: boolean
  secondary?: boolean
}

const Button: React.FC<MButtonProps & ButtonProps> = props => {
  const classes = useStyles()
  const { id, onChange, type, disabled, children, loading, secondary, ...rest } = props

  return (
    <MButton
      id={id}
      className={props.className}
      classes={{ root: joinClassnames(classes.root, secondary ? classes.secondary : undefined) }}
      onClick={props.onClick}
      onChange={onChange}
      type={type}
      disabled={loading || disabled}
      {...rest}
    >
      {children}
      <Fade in={loading}>
        <div className={classes.loadingContainer}>
          <CircularProgress
            size={26}
            classes={{ root: classes.circularProgress }}
            color="secondary"
          />
        </div>
      </Fade>
    </MButton>
  )
}

export default Button
