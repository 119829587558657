import React, { useState } from "react"
import { Paper, Table as MUITable, TableBody, CircularProgress } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import TableHeader from "./TableHeader"
import TableRow from "./TableRow"
import TableFooter from "./TableFooter"
import { color } from "../../theme"
import { observer } from "mobx-react"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles({
  tableContainer: {
    padding: "30px 50px",
    position: "relative",
  },
  root: {
    alignSelf: "center",
    width: "100%",
    overflowX: "auto",
    maxWidth: 1200,
    backgroundColor: color.palette.white,
    position: "relative",
  },
  table: {
    minWidth: 600,
  },
  rounded: {
    border: `1px solid ${color.lighterLine}`,
  },
  loading: {
    position: "absolute",
    top: "50%",
    left: "50%",
    color: color.dim,
  },
  search: {
    width: 270,
    marginBottom: 24,
  },
  placeholderContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    justifyContent: "center",
    alignItems: "flex-start",
    position: "absolute",
    top: 0,
    bottom: 0,
  },
  placeholderText: {
    fontSize: 30,
    color: color.dim,
    textIndent: 50,
  },
  placeholderTextSub: {
    fontSize: 18,
    color: color.dim,
    textIndent: 50,
  },
})

interface TableProps {
  loading?: boolean
  total: number
  page?: number
  pageSize?: number
  onChangePage?(event: any, toPage: number): void
  toSingle?(id: number): void
  items: Record<string, any>[]
  headerTitles: string[]
}

/**
 * A somewhat reusable Table component.
 * Make sure keys of the objects passed as items that you want to display
 * match the provided headerTitles
 */
export const Table: React.FC<TableProps> = observer(props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    loading,
    total,
    page = 1,
    pageSize = 10,
    onChangePage,
    toSingle,
    items,
    headerTitles,
  } = props

  const [currentPage, setCurrentPage] = useState(page)
  const [currentPageSize, setCurrentPageSize] = useState(pageSize)

  const handlePageChange = (event: any, toPage: number) => {
    setCurrentPage(toPage)
    if (onChangePage) onChangePage(event, toPage)
  }

  const renderLoading = () => {
    if (loading) return <CircularProgress className={classes.loading} size={30} />
    return null
  }

  const renderRows = () => {
    if (items && items.length) {
      return items.map(item => (
        <TableRow key={item.title} item={item} toSingle={toSingle} keys={headerTitles} />
      ))
    }
    return null
  }

  const renderFooter = () => {
    if (total < pageSize) return null
    return (
      <TableFooter
        page={currentPage}
        pageSize={currentPageSize}
        onChangePage={handlePageChange}
        total={total}
      />
    )
  }

  const renderContent = () => {
    if (!total || !items.length) {
      return (
        <div className={classes.placeholderContainer}>
          <span className={classes.placeholderText}>{t("common.empty")}</span>
          <span className={classes.placeholderTextSub}>{t("common.emptySub")}</span>
        </div>
      )
    }
    return (
      <div className={classes.tableContainer}>
        <Paper className={classes.root} classes={{ rounded: classes.rounded }} elevation={0}>
          <MUITable className={classes.table} padding="none">
            <TableHeader headerTitles={headerTitles} />
            <TableBody>{renderRows()}</TableBody>
          </MUITable>
          {renderLoading()}
        </Paper>
        {renderFooter()}
      </div>
    )
  }

  return renderContent()
})
