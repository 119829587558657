import React, { FC } from "react"
import { observer } from "mobx-react"
import {
  createStyles,
  Snackbar,
  WithStyles,
  withStyles,
  SnackbarContent,
  IconButton,
  Theme,
  colors,
} from "@material-ui/core"
import { CheckCircle, Warning, Info, Close, Error } from "@material-ui/icons"
import { withTranslation, WithTranslation } from "react-i18next"
import { useStores } from "../../models/root-store"
import { NotificationType } from "../../models/user-store/notification-store"
import { SnackbarOrigin } from "@material-ui/core/Snackbar"

const notificationStyles = () =>
  createStyles({
    container: {
      zIndex: 10000,
    },
  })

const styles = (theme: Theme) =>
  createStyles({
    success: {
      backgroundColor: colors.green[600],
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    icon: {
      fontSize: 20,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: 4,
    },
    message: {
      display: "flex",
      alignItems: "center",
    },
  })

interface CustomSnackbarContentProps extends WithStyles<typeof styles> {
  message: string | null | undefined
  onClose: () => void
  variant: NotificationType
}

const variantIcon = {
  success: CheckCircle,
  warning: Warning,
  error: Error,
  info: Info,
}

const CustomSnackbarContent: React.ComponentType<CustomSnackbarContentProps> = props => {
  const { classes, message, onClose, variant } = props
  const Icon = variantIcon[variant]
  const classVariant = (classes as any)[variant]

  return (
    <SnackbarContent
      className={classVariant}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={`${classes.icon} ${classes.iconVariant}`} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
          <Close className={classes.icon} />
        </IconButton>,
      ]}
    />
  )
}

const CustomSnackbarContentWrapper = withStyles(styles)(CustomSnackbarContent)

interface OwnProps {
  anchorOrigin?: SnackbarOrigin
}

const LayoutNotification: FC<WithStyles & WithTranslation & OwnProps> = props => {
  const { classes, t } = props
  const { notificationStore } = useStores()
  const { message, type } = notificationStore
  const onClose = () => {
    notificationStore.clear()
  }

  const renderContent = () => {
    if (!message || !type) {
      return undefined
    }
    return <CustomSnackbarContentWrapper onClose={onClose} variant={type} message={t(message)} />
  }

  return (
    <Snackbar
      anchorOrigin={props.anchorOrigin || { vertical: "top", horizontal: "right" }}
      open={!!message}
      className={classes.container}
    >
      {renderContent()}
    </Snackbar>
  )
}

export default withStyles(notificationStyles)(withTranslation()(observer(LayoutNotification)))
