import React from "react"
import { withStyles, createStyles, Theme } from "@material-ui/core/styles"
import { TextField, WithStyles } from "@material-ui/core/"
import { TextFieldProps } from "@material-ui/core/TextField"
import { color } from "../theme"
import { useTranslation } from "react-i18next"

const styles = ({ breakpoints }: Theme) =>
  createStyles({
    container: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    input: {
      height: 50,
      fontSize: 16,
      color: color.text,
      backgroundColor: color.white,
      justifyContent: "center",
      borderRadius: 3,
      border: `1px solid ${color.line}`,
      padding: "0px 15px",
      boxSizing: "border-box",
      "&::placeholder": {
        color: color.dim,
      },
      position: "relative",
      [breakpoints.down("sm")]: {
        height: 40,
        fontSize: 14,
      },
    },
    multiline: {
      minHeight: 180,
      fontSize: 18,
      color: color.text,
      backgroundColor: color.transparent,
      borderRadius: 3,
      border: `1px solid ${color.line}`,
      padding: 15,
      boxSizing: "border-box",
      "&::placeholder": {
        color: color.dim,
      },
      [breakpoints.down("sm")]: {
        height: 80,
        fontSize: 14,
      },
    },
  })

interface InputProps extends WithStyles<typeof styles> {
  width?: string
  maxLength?: number
  max?: number
  min?: number
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

const Input: React.FC<InputProps & TextFieldProps> = props => {
  const { t } = useTranslation()
  const {
    classes,
    id,
    className,
    value,
    onChange,
    placeholder,
    width,
    type,
    rows,
    rowsMax,
    multiline,
    autoFocus,
    required,
    disabled,
    onKeyDown,
  } = props

  const getInputClassName = () => {
    return multiline ? classes.multiline : classes.input
  }

  const onFocus = () => {}
  const styles = width ? { width } : {}

  return (
    <div className={classes.container} style={styles}>
      <TextField
        id={id}
        value={value}
        onChange={onChange}
        placeholder={placeholder && t(placeholder)}
        type={type || "text"}
        rows={rows}
        rowsMax={rowsMax}
        multiline={multiline}
        autoFocus={autoFocus}
        onFocus={onFocus}
        required={required}
        disabled={disabled}
        InputProps={{
          disableUnderline: true,
          classes: { input: `${getInputClassName()} ${className}` },
        }}
        onKeyDown={onKeyDown}
      />
    </div>
  )
}

export default withStyles(styles)(Input)
